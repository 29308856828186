<template>
  <div class="ahieve-page">
    <!--achieve-one start-->
    <div class="achieve-one">
      <!--one-box start-->
      <div class="one-box">
        <div class="one-con">
          <div class="achieve-tit">今日目标</div>
          <ul class="flex-ul">
            <li>
              <router-link :to="`/addTourist?type=1&id=${id}`">
                <div class="t">
                  <span class="color_red">{{ details.day_clientCount }}</span>
                  /{{ details.day_new_clients_num }}
                </div>
                <div class="c">新增客户</div>
              </router-link>
            </li>
            <li>
              <router-link :to="`/addHouse?type=1&id=${id}`">
                <div class="t">
                  <span class="color_red">{{ details.day_houseCount }}</span>
                  /{{ details.day_new_house_num }}
                </div>
                <div class="c">新增房源</div>
              </router-link>
            </li>
            <li>
              <router-link :to="`/bespectacled?type=1&id=${id}`">
                <div class="t">
                <span class="color_red">{{
                  details.day_daiLookCount
                }}</span>
                /{{ details.day_new_take_look_num }}
              </div>
              <div class="c">新增带看</div>
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/perIn?type=1&id=${id}`">
                <div class="t">
                <span class="color_red">{{details.day_genLookCount  }}</span>
                /{{ details.day_new_follow_num }}
              </div>
              <div class="c">新增跟进</div>
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=2&id=${id}&types=1`">
                <div class="t">
                <span class="color_red">{{ details.day_key_num }}</span> /{{
                  details.day_new_key_num
                }}
              </div>
              <div class="c">新增钥匙方</div>
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=1&id=${id}&types=1`">
                <div class="t">
                <span class="color_red">{{details.day_msg_num  }}</span> /{{
                  details.day_new_msg_num
                }}
              </div>
              <div class="c">新增信息方</div>
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=3&id=${id}&types=1`">
                <div class="t">
                <span class="color_red">{{ details.day_wt_num }}</span> /{{
                  details.day_new_wt_num
                }}
              </div>
              <div class="c">新增委托方</div>
              
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=4&id=${id}&types=1`">
                <div class="t">
                <span class="color_red">{{details.day_img_num  }}</span> /{{
                  details.day_new_img_num
                }}
              </div>
              <div class="c">新增图片方</div>
              
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=3&id=${id}&types=1`">
                <div class="t">
                <span class="color_red">{{ details.day_wh_num }}</span> /{{
                  details.day_new_wh_num
                }}
              </div>
              <div class="c">新增维护方</div>
              
              </router-link>
              
            </li>
          </ul>
        </div>
      </div>
      <!--one-box end-->
      <!--one-box start-->
      <div class="one-box">
        <div class="one-con">
          <div class="achieve-tit">本月目标</div>
          <ul class="flex-ul">
            <li>
              <router-link :to="`/addTourist?type=2&id=${id}`">
                <div class="t">
                  <span class="color_red">{{ details.mouth_clientCount }}</span>
                  /{{ details.mouth_new_clients_num }}
                </div>
                <div class="c">新增客户</div>
              </router-link>
            </li>
            <li>
              <router-link :to="`/addHouse?type=2&id=${id}`">
                <div class="t">
                  <span class="color_red">{{ details.mouth_houseCount }}</span>
                  /{{ details.mouth_new_house_num }}
                </div>
                <div class="c">新增房源</div>
              </router-link>
            </li>
            <li>
              <router-link :to="`/bespectacled?type=2&id=${id}`">
                <div class="t">
                <span class="color_red">{{ details.mouth_daiLookCount }}</span>
                /{{ details.mouth_new_take_look_num }}
              </div>
              <div class="c">新增带看</div>
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/perIn?type=2&id=${id}`">
                <div class="t">
                <span class="color_red">{{ details.mouth_genLookCount }}</span>
                /{{ details.mouth_new_follow_num }}
              </div>
              <div class="c">新增跟进</div>
              
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=2&id=${id}&types=2`">
                <div class="t">
                <span class="color_red">{{ details.mouth_key_num }}</span>
                /{{ details.mouth_new_key_num }}
              </div>
              <div class="c">新增钥匙方</div>
              
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=1&id=${id}&types=2`">
                <div class="t">
                <span class="color_red">{{ details.mouth_msg_num }}</span>
                /{{ details.mouth_new_msg_num }}
              </div>
              <div class="c">新增信息方</div>
              
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=3&id=${id}&types=2`">
                <div class="t">
                <span class="color_red">{{ details.mouth_wt_num }}</span>
                /{{ details.mouth_new_wt_num }}
              </div>
              <div class="c">新增委托方</div>
              </router-link>
              
            </li>
            <li>
              <router-link :to="`/addSide?type=4&id=${id}&types=2`">
                <div class="t">
                <span class="color_red">{{ details.mouthy_img_num }}</span>
                /{{ details.mouth_new_img_num }}
              </div>
              <div class="c">新增图片方</div>
              
              </router-link>
              
            </li>
            <li>

              <router-link :to="`/addSide?type=3&id=${id}&types=2`">
                <div class="t">
                <span class="color_red">{{ details.mouth_wh_num }}</span> /{{
                  details.mouth_new_wh_num
                }}
              </div>
              <div class="c">新增维护方</div>
              </router-link>
              
            </li>
          </ul>
        </div>
      </div>
      <!--one-box end-->
    </div>
    <!--achieve-one end-->
    <!--achieve-two start-->
    <div class="achieve-two">
      <div class="flex flex-between">
        <div class="achieve-pos">
          <router-link to=""> 区域总监</router-link>
          <span class=""> > </span>
          <router-link to="">中原区店长</router-link>
          <span class="color_blue"> > </span>
          <span class="color_blue">经理</span>
        </div>
        <div class="tit-r">
          <el-button class="achieve-btn" @click="$refs.juris.open()"
            >权限设置</el-button
          >
        </div>
      </div>
      <!-- <div class="achieve-hd">
        <div class="li active">5星</div>
        <div class="li">5星</div>
        <div class="li">5星</div>
        <div class="li">5星</div>
        <div class="li">5星</div>
      </div> -->
      <!--two-item start-->
      <div class="two-item" v-for="(item, index) in list" :key="index">
        <div class="two-l">
          <img class="img" :src="item.image" />
          <div class="two-txt">
            <div class="t">
              <span class="name m-r-20">{{ item.name }}</span>
              <span class="tel m-r-20">{{ item.mobile }}</span>
            </div>
            <div class="c-flex">
              <div class="c">工号：{{ item.job_number }}</div>
              <!-- <div class="eva-icon m-l-15">
                <i class="el-icon-star-on"></i>
                <i class="el-icon-star-on"></i>
                <i class="el-icon-star-on"></i>
              </div> -->
            </div>
          </div>
        </div>
        <div class="two-r">
          <div class="r-hd">
            <div class="see-a color_orange m-r-20" @click="$refs.apop.open(item.id)">
              <img class="icon" src="../../assets/image/personage/icon11.png" />
              <span class="t">查看业绩</span>
            </div>
            <router-link :to="`/personage/achieveA?id=${item.id}`" class="see-a color_blue">
              <img class="icon" src="../../assets/image/personage/icon12.png" />
              <span class="t">查看下级</span>
            </router-link>
          </div>
        </div>
      </div>
      <!--two-item end-->
    </div>
    <!--achieve-two end-->
    <!-- 制定目标弹框 S -->
    <formu-pop ref="fpop"></formu-pop>
    <!-- 制定目标弹框 E -->
    <!-- 查看业绩弹框 S -->
    <achieve-pop ref="apop"></achieve-pop>
    <!-- 查看业绩弹框 E -->
    <!-- 权限设置弹框 S -->
    <jurisdiction-set ref="juris"></jurisdiction-set>
    <!-- 权限设置弹框 E -->
  </div>
</template>

<script>
import FormuPop from "../../components/FormuPop";
import AchievePop from "../../components/AchievePop";
import JurisdictionSet from "../../components/JurisdictionSet";
// import 'swiper/css/swiper.css'
export default {
  components: { FormuPop, AchievePop, JurisdictionSet },
  name: "Achieve",
  data () {
    return {
      from:{
        dian_id:'',
        page:1,
        id:null
      },
      total:0,//总条数
      list:[],
      details: {},
    }
  },
  methods:{
    getList () {
      this.$axios.nextManagersList(this.from).then(res=>{
        this.list = res.data.data
        this.total = res.data.total
      })
    },
    getDetails() {
      this.$axios
        .checkYeJi({
          id: this.from.id
        })
        .then((res) => {
          this.details = res.data;
        });
    },
  },
  created () {
    this.from.dian_id = this.$route.query.dian_id
    this.from.id = this.$route.query.id
    this.getList()
    this.getDetails();
  }
};
</script>

<style scoped lang="less">
.achieve-hd {
  display: flex;
  margin: 0 -10px;
  .li {
    background: #f5f5f5;
    border-radius: 4px;
    line-height: 32px;
    padding: 0 14px;
    margin: 5px 10px;
    font-size: 16px;
    color: #666;
  }
  .active {
    background: #e1ebff;
    border: 1px solid #3273f6;
    color: #3273f6;
  }
}
.achieve-pos {
  font-size: 14px;
  color: #666;
  display: flex;
}
.achieve-one {
  display: flex;
  justify-content: space-between;
  .one-box {
    width: 480px;
    background: #fff;
  }
  .one-con {
    padding: 20px 30px;
    .flex-ul {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0px 10px 0px;
      li {
        width: 33.333%;
        text-align: center;
        margin: 15px 0px;
      }
      .t {
        font-size: 20px;
      }
      .c {
        font-size: 16px;
        color: #666;
        margin-top: 5px;
      }
    }
  }
}
.achieve-tit {
  font-size: 18px;
  position: relative;
  padding-left: 15px;
}
.achieve-tip {
  background: #fffaf0;
  border-radius: 4px;
  padding: 10px 15px;
  line-height: 30px;
  color: #ffa914;
  font-size: 16px;
  display: flex;
  .icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  .c {
    font-size: 16px;
    line-height: 30px;
  }
}
.achieve-tip.red {
  background: #ffecec;
  color: #ff5151;
}
.achieve-tit:before {
  display: block;
  width: 4px;
  height: 20px;
  background: #3273f6;
  border-radius: 2px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -10px;
  content: "";
}
.achieve-btn {
  width: 100px;
  height: 36px;
  border-radius: 4px;
  padding: 0px;
  border: 1px solid #3273f6;
  color: #3273f6;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
}
.achieve-btn.blue {
  background: #3273f6;
  color: #fff;
}
.achieve-two {
  background: #fff;
  padding: 20px;
  margin-top: 20px;
  .c-flex {
    display: flex;
    align-items: center;
  }
  .eva-icon {
    color: #ffb422;
  }
  .two-item {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #ebebeb;
    align-items: center;
    .two-l {
      display: flex;
      .img {
        width: 44px;
        height: 44px;
        border-radius: 100%;
      }
      .two-txt {
        margin-left: 15px;
        .t {
          font-size: 16px;
          display: flex;
        }
        .c {
          font-size: 14px;
          color: #666;
          margin: 10px 0px;
          line-height: 1.5;
        }
      }
    }
    .two-r {
      .r-hd {
        display: flex;
        align-items: center;
        .see-a {
          display: flex;
          align-items: center;
          font-size: 14px;
          cursor: pointer;
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }
      .r-bd {
        display: flex;
        align-items: center;
        margin-top: 12px;
        .name {
          font-size: 14px;
          color: #666;
        }
        .gress {
          width: 120px;
          height: 8px;
          background: #ebebeb;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
        }
        .gress-s {
          background: #3273f6;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 8px;
        }
      }
    }
  }
  .two-item:nth-last-child(1) {
    border: none;
  }
}
</style>
